<template>
  <div class="locations-create">
    <ui-wizard
      :title="$t('locations.create.title')"
      :breadcrumbs="breadcrumbs"
      :is-saving="isSaving"
      :is-loading="isLoading"
      @back="onBack"
      @on-complete="onComplete"
      @update:startIndex="updateIndex"
      :is-valid="!$v.$error"
    >
      <ui-wizard-step :title="$t('locations.create.step.location')" :before-change="checkLocator">
        <div class="locations-create__wrapper">
          <ui-dropdown
            id="locations-client"
            class="locations-create__wrapper__input"
            v-model="locationType"
            :options="currentClient.locationTypes"
            :placeholder="$t('locations.create.locationType.placeholder')"
            :dropdown-label="$t('locations.create.locationType.label')"
            :show-label="true"
            :error="$v.locationType.$error"
            open-direction="below"
            :is-required="true"
          >
            <template v-slot:helper v-if="$v.locationType.$error && !$v.locationType.required">
              {{ $t('errors.required') }}
            </template>
          </ui-dropdown>
          <ui-input
            class="locations-create__wrapper__input"
            v-model.trim="externalId"
            :label="$t('locations.create.externalId')"
            id="locations-externalId"
            :error="$v.externalId.$error"
            @input="delayTouch($v.externalId)"
          >
            <template v-if="$v.externalId.$error && !$v.externalId.isUnique">
              {{ $t('errors.unique') }}
            </template>
          </ui-input>
          <ui-input
            class="locations-create__wrapper__input"
            v-model.trim="name"
            :label="$t('locations.create.name')"
            id="locations-name"
            :error="$v.name.$error"
            :is-required="true"
          >
            <template v-if="$v.name.$error && !$v.name.required">
              {{ $t('errors.required') }}
            </template>
          </ui-input>
          <div class="locations-create__wrapper__address">
            <ui-input
              class="locations-create__wrapper__address__input locations-create__wrapper__address__input--successive"
              v-model="street1"
              :label="$t('locations.create.street1')"
              id="location-street1"
              :error="$v.street1.$error"
              :is-required="true"
            >
              <template v-if="$v.street1.$error && !$v.street1.required">
                {{ $t('errors.required') }}
              </template>
            </ui-input>
            <ui-input
              class="locations-create__wrapper__address__input"
              v-model="street2"
              :label="$t('locations.create.street2')"
              id="location-street2"
              design="minimalist"
            >
            </ui-input>
          </div>
          <ui-input
            class="locations-create__wrapper__input"
            :is-required="true"
            v-model="postalCode"
            :label="$t('locations.create.postalCode')"
            id="location-postalCode"
          >
          </ui-input>
          <ui-input
            class="locations-create__wrapper__input"
            v-model="city"
            :label="$t('locations.create.city')"
            id="location-city"
            :is-required="true"
            :error="$v.city.$error"
          >
            <template v-if="$v.city.$error && !$v.city.required">
              {{ $t('errors.required') }}
            </template>
          </ui-input>
          <ui-dropdown
            class="locations-create__wrapper__input"
            id="locations-country-code"
            v-model="countryCode"
            :options="countries"
            :is-required="true"
            label="name"
            track-by="countryCode"
            :placeholder="$t('locations.create.countryCode.placeholder')"
            :dropdown-label="$t('locations.create.countryCode.label')"
            :show-label="true"
            :error="$v.countryCode.$error"
            open-direction="above"
          >
            <template v-slot:helper v-if="$v.countryCode.$error && !$v.countryCode.required">
              {{ $t('errors.required') }}
            </template>
          </ui-dropdown>
          <ui-input
            class="locations-create__wrapper__input"
            v-model="phone"
            :label="$t('locations.create.phone')"
            id="location-phone"
          />
        </div>
      </ui-wizard-step>

      <ui-wizard-step :title="$t('locations.create.step.geo')" :before-change="checkClient">
        <div class="locations-create__grid">
          <screen-block
            :title="$t('locations.create.internationalPhone')"
            icon="phone"
            :has-data="internationalPhone !== ''"
            :data="internationalPhone"
            ratio="1-2"
            :is-loading="isInternationalPhoneUpdate"
            @click="
              editSimpleField('modal-simple-field', 'internationalPhone', internationalPhone, 'internationalPhone')
            "
          />

          <screen-block
            :title="$t('locations.create.nationalPhone')"
            icon="phone"
            :has-data="nationalPhone !== ''"
            :data="nationalPhone"
            ratio="1-2"
            :is-loading="isInternationalPhoneUpdate"
            @click="editSimpleField('modal-simple-field', 'nationalPhone', nationalPhone, 'nationalPhone')"
          />

          <screen-block
            class="locations-create__grid__coordinates"
            :title="$t('locations.create.gps')"
            icon="geoloc"
            :has-data="
              typeof currentCoordinates.latitude === 'string' && typeof currentCoordinates.longitude === 'string'
            "
            :is-required="true"
            ratio="1-2"
            :is-loading="isCoordinatesUpdate"
            @click="editSimpleField('modal-coordinates')"
          >
            <template v-slot:data>
              <div class="locations-create__grid__coordinates__section">
                <div class="locations-create__grid__coordinates__section__label">
                  {{ $t('common.label.latitude') }}
                </div>
                <div class="locations-create__grid__coordinates__section__value">
                  {{ currentCoordinates.latitude }}
                </div>
              </div>
              <div class="locations-create__grid__coordinates__section">
                <div class="locations-create__grid__coordinates__section__label">
                  {{ $t('common.label.longitude') }}
                </div>
                <div class="locations-create__grid__coordinates__section__value">
                  {{ currentCoordinates.longitude }}
                </div>
              </div>
            </template>
          </screen-block>

          <screen-block
            :title="$t('locations.create.subDivisions')"
            icon="world"
            :is-required="true"
            :has-data="
              typeof currentLocation.subDivision1 === 'string' &&
              currentLocation.subDivision1 !== '' &&
              subDivisions !== null
            "
            ratio="1-1"
            :is-loading="isSubDivisionUpdate"
            @click="editSimpleField('modal-sub-divisions')"
          >
            <template v-slot:data>
              <div v-if="currentLocation.subDivision1">
                {{ mapSubDivision(currentLocation.subDivision1, 'level1') }}
              </div>
              <div v-if="currentLocation.subDivision2">
                {{ mapSubDivision(currentLocation.subDivision2, 'level2') }}
              </div>
              <div v-if="currentLocation.subDivision3">
                {{ mapSubDivision(currentLocation.subDivision3, 'level3') }}
              </div>
            </template>
          </screen-block>
        </div>
      </ui-wizard-step>
      <ui-wizard-step :title="$t('locations.create.step.features')" :before-change="() => true">
        <div class="locations-create__featuresSelection">
          <feature-list-selection
            displaymode="grid"
            :features="clientFeaturesListWithChildFilled"
            :activeList="featuresActiveList"
            :exceptions="featuresExceptionsList"
            :parameters="{ mode: 'location' }"
            @inputDataChanged="onFeaturesInputDataChanged"
          />
        </div>
      </ui-wizard-step>
      <ui-wizard-step v-if="activeClientLocator" :title="$t('locations.create.step.lms')" :before-change="checkLocator">
        <screen-block
          ratio="1-2"
          :title="$t('locations.id.general.section.productLocator.title')"
          :helper="$t('locations.create.productLocator.helpers.tab')"
          align="center"
          :has-data="true"
          :class="{
            'locations-create__lms__status--active': productLocatorStatus,
            'locations-create__lms__status--inactive': !productLocatorStatus,
          }"
          :is-editable="false"
        >
          <template v-slot:icon>
            <div class="locations-create__lms__status__icon backoffice-icons">delivery</div>
          </template>
          <template v-slot:data>
            <div class="locations-create__lms__status__state">
              <template v-if="productLocatorStatus">
                {{ $t('locations.create.productLocator.status.active') }}
              </template>
              <template v-else-if="!productLocatorStatus">
                {{ $t('locations.create.productLocator.status.inactive') }}
              </template>
            </div>
          </template>
          <template v-slot:right>
            <ui-switch
              class="locations-create__lms__status__toggle"
              id="active-locator"
              :standalone="true"
              :value="productLocatorStatus"
              :input-value="productLocatorStatus"
              @input="value => (productLocatorStatus = value)"
            ></ui-switch>
          </template>
        </screen-block>

        <div class="locations-create__wrapper">
          <ui-input
            class="locations-create__wrapper__input"
            v-model="leadProductAuthorizeFrom"
            :label="$t('locations.create.leadProductAuthorizeFrom')"
            id="leadProductAuthorizeFrom"
          />
          <ui-input
            class="locations-create__wrapper__input"
            v-model="warehouse"
            :label="$t('locations.create.warehouse')"
            id="warehouse"
          />
          <ui-input
            class="locations-create__wrapper__input"
            v-model.trim="leadProductEmail"
            :label="$t('locations.create.leadProductEmail')"
            id="locations-leadProductEmail"
            :error="$v.leadProductEmail.$error"
            :is-required="true"
          >
            <template v-if="$v.leadProductEmail.$error && !$v.leadProductEmail.email">
              {{ $t('errors.email') }}
            </template>
          </ui-input>

          <ui-input
            class="locations-create__wrapper__input"
            v-model.trim="leadProductGenerationsEmailsString"
            :label="$t('locations.create.leadProductGenerationsEmails')"
            id="locations-leadProductGenerationsEmails"
            :is-required="true"
            :readonly="true"
            @click.native="
              editList(
                'leadProductGenerationsEmails',
                'email',
                'list',
                'email',
                'locations.id.modal.leadGenerationsEmails.tag'
              )
            "
          />
        </div>
      </ui-wizard-step>
      <ui-wizard-step :title="$t('locations.create.step.summary')">
        <div class="locations-create__grid">
          <screen-block
            :title="$t('locations.create.label.client')"
            icon="business"
            :has-data="currentClient !== null && typeof currentClient.name === 'string'"
            :data="currentClient.name"
            ratio="1-2"
            :is-loading="false"
            :is-editable="false"
          />
          <screen-block
            :title="$t('locations.create.label.externalId')"
            icon="business"
            :has-data="externalId !== ''"
            :data="externalId"
            ratio="1-2"
            :is-loading="false"
            :is-editable="false"
          />
          <screen-block
            :title="$t('locations.create.label.location')"
            icon="location"
            :has-data="name !== ''"
            :data="name"
            ratio="1-2"
            :is-loading="false"
            :is-editable="false"
          />
          <screen-block
            :title="$t('locations.create.label.address')"
            icon="pin"
            :has-data="street1 !== ''"
            :data="name"
            ratio="1-2"
            :is-loading="false"
            :is-editable="false"
          >
            <template v-slot:data>
              <div>
                {{ street1 }}
              </div>
              <div v-if="street2">
                {{ street2 }}
              </div>
              <div>
                <span v-if="postalCode">{{ postalCode }}</span>
                {{ city }}
              </div>
              <div v-if="countryCode">
                {{ countryCode.name }}
              </div>
            </template>
          </screen-block>
          <screen-block
            class="locations-create__gps"
            :title="$t('locations.create.label.gps')"
            icon="geoloc"
            :has-data="
              typeof currentCoordinates.latitude === 'string' && typeof currentCoordinates.longitude === 'string'
            "
            ratio="1-2"
            :is-loading="false"
            :is-editable="false"
          >
            <template v-slot:data>
              <div class="locations-create__gps__coordinates__section">
                <div class="locations-create__gps__coordinates__section__label">
                  {{ $t('common.label.latitude') }}
                </div>
                <div class="locations-create__gps__coordinates__section__value">
                  {{ currentCoordinates.latitude }}
                </div>
              </div>
              <div class="locations-create__gps__coordinates__section">
                <div class="locations-create__gps__coordinates__section__label">
                  {{ $t('common.label.longitude') }}
                </div>
                <div class="locations-create__gps__coordinates__section__value">
                  {{ currentCoordinates.longitude }}
                </div>
              </div>
            </template>
          </screen-block>
          <screen-block
            :title="$t('locations.create.label.subDivisions')"
            icon="world"
            :has-data="
              typeof currentLocation.subDivision1 === 'string' &&
              currentLocation.subDivision1 !== '' &&
              subDivisions !== null
            "
            ratio="1-2"
            :is-loading="false"
            :is-editable="false"
          >
            <template v-slot:data>
              <div v-if="currentLocation.subDivision1">
                {{ mapSubDivision(currentLocation.subDivision1, 'level1') }}
              </div>
              <div v-if="currentLocation.subDivision2">
                {{ mapSubDivision(currentLocation.subDivision2, 'level2') }}
              </div>
              <div v-if="currentLocation.subDivision3">
                {{ mapSubDivision(currentLocation.subDivision3, 'level3') }}
              </div>
            </template>
          </screen-block>
          <screen-block
            :title="$t('locations.create.label.phone')"
            icon="phone"
            :has-data="phone !== ''"
            :data="phone"
            ratio="1-2"
            :is-loading="false"
            :is-editable="false"
          />
          <screen-block
            :title="$t('locations.create.label.internationalPhone')"
            icon="phone"
            :has-data="internationalPhone !== ''"
            :data="internationalPhone"
            ratio="1-2"
            :is-loading="false"
            :is-editable="false"
          />
          <screen-block
            :title="$t('locations.create.label.nationalPhone')"
            icon="phone"
            :has-data="nationalPhone !== ''"
            :data="nationalPhone"
            ratio="1-2"
            :is-loading="false"
            :is-editable="false"
          />
          <screen-block
            :title="$t('locations.create.label.locationType')"
            icon="attribut"
            :has-data="locationType !== null && typeof locationType === 'string'"
            :data="locationType"
            ratio="1-2"
            :is-loading="false"
            :is-editable="false"
          />
        </div>

        <ui-title
          v-if="activeClientLocator"
          class="locations-create__grid__title"
          title="Product locator"
          contentClasses="ui-title--md"
        />
        <div v-if="activeClientLocator" class="locations-create__grid">
          <screen-block
            ratio="1-1"
            :title="$t('locations.id.general.section.productLocator.title')"
            :helper="$t('locations.create.productLocator.helpers.tab')"
            align="center"
            :has-data="true"
            :class="{
              'locations-create__lms__status--active': productLocatorStatus,
              'locations-create__lms__status--inactive': !productLocatorStatus,
            }"
            :is-editable="false"
          >
            <template v-slot:icon>
              <div class="locations-create__lms__status__icon backoffice-icons">delivery</div>
            </template>
            <template v-slot:data>
              <div class="locations-create__lms__status__state">
                <template v-if="productLocatorStatus">
                  {{ $t('locations.create.productLocator.status.active') }}
                </template>
                <template v-else-if="!productLocatorStatus">
                  {{ $t('locations.create.productLocator.status.inactive') }}
                </template>
              </div>
            </template>
            <template v-slot:right>
              <ui-switch
                class="locations-create__lms__status__toggle"
                id="active-locator"
                :standalone="true"
                :value="productLocatorStatus"
                :input-value="productLocatorStatus"
                :disabled="true"
              ></ui-switch>
            </template>
          </screen-block>
          <screen-block
            :title="$t('locations.create.leadProductAuthorizeFrom')"
            icon="attribut"
            :has-data="leadProductAuthorizeFrom !== '' && typeof leadProductAuthorizeFrom === 'string'"
            :data="leadProductAuthorizeFrom"
            ratio="1-2"
            :is-loading="false"
            :is-editable="false"
          />
          <screen-block
            :title="$t('locations.create.warehouse')"
            icon="attribut"
            :has-data="warehouse !== '' && typeof warehouse === 'string'"
            :data="warehouse"
            ratio="1-2"
            :is-loading="false"
            :is-editable="false"
          />
          <screen-block
            :title="$t('locations.create.leadProductEmail')"
            icon="email"
            :has-data="leadProductEmail !== '' && typeof leadProductEmail === 'string'"
            :data="leadProductEmail"
            ratio="1-2"
            :is-loading="false"
            :is-editable="false"
          />
          <screen-block
            :title="$t('locations.create.leadProductGenerationsEmails')"
            icon="email"
            :has-data="
              leadProductGenerationsEmailsString !== '' && typeof leadProductGenerationsEmailsString === 'string'
            "
            :data="leadProductGenerationsEmailsString"
            ratio="1-2"
            :is-loading="false"
            :is-editable="false"
          />
        </div>
        <feature-list-card
          class="locations-create__grid__features"
          :featureListDetails="featuresActiveListDetails"
          :featureList="
            Object.keys(featuresActiveList)
              .map(key => ({
                tag: key,
                list: featuresActiveList[key],
              }))
              .filter(item => item.list != null && item.list.length > 0)
          "
        />
      </ui-wizard-step>
      <modal-simple-field
        v-if="isEditing && editingModal === 'modal-simple-field' && !isInternationalPhoneUpdate"
        :title="modalTitle"
        :is-updating="isInternationalPhoneUpdate"
        :data="modal.data"
        :label="modal.label"
        :obj-key="modal.objKey"
        :parameters="modal.parameters"
        @save="saveField($event, 'modal-simple-field')"
        @closed="closedModal"
      />
      <modal-coordinates
        v-if="isEditing && editingModal === 'modal-coordinates' && !isCoordinatesUpdate"
        :title="modalTitle"
        :is-updating="isCoordinatesUpdate"
        :data="currentCoordinates"
        @save="saveField($event, 'modal-coordinates')"
        @closed="closedModal"
      />
      <modal-sub-divisions
        :data="currentLocation"
        :sub-divisions="subDivisions"
        v-if="isEditing && editingModal === 'modal-sub-divisions' && !isSubDivisionUpdate"
        :title="modalTitle"
        :is-updating="isSubDivisionUpdate"
        :current-coordinates="currentCoordinates"
        @save="saveField($event, 'modal-sub-divisions')"
        @closed="closedModal"
      />
    </ui-wizard>
  </div>
</template>

<script>
import { getInternationalPhone, geocode, getGeonames } from '@/services/tool.service'
import UiWizard from '@/components/UI/Wizard/Wizard.vue'
import UiWizardStep from '@/components/UI/Wizard/Step.vue'
import UiInput from '@/components/UI/Input.vue'
import UiTitle from '@/components/UI/Title.vue'
import UiSwitch from '@/components/UI/Switch.vue'
import UiDropdown from '@/components/UI/Dropdown.vue'
import ScreenBlock from '@/components/Screen/Block.vue'
import ModalSimpleField from '@/components/Modal/SimpleField.vue'
import ModalCoordinates from '@/components/Modal/Coordinates.vue'
import ModalSubDivisions from '@/components/Modal/SubDivisions.vue'
import FeatureListSelection from '@/components/FeatureList/FeatureListSelection.vue'
import FeatureListCard from '@/components/FeatureList/FeatureListCard.vue'
import { notif } from '@/mixins/notification.mixin'
import { mapActions, mapState } from 'vuex'
import { requiredIf, email } from 'vuelidate/lib/validators'
import { locationCreateGeoip } from '@/services/location.service'
import { getShortLocal } from '@/utils/string.util'

const touchMap = new WeakMap()

export default {
  name: 'LocationsCreate',
  components: {
    UiWizard,
    UiWizardStep,
    UiInput,
    UiSwitch,
    UiDropdown,
    UiTitle,
    ScreenBlock,
    ModalCoordinates,
    ModalSubDivisions,
    ModalSimpleField,
    FeatureListSelection,
    FeatureListCard,
  },
  mixins: [notif],
  data() {
    return {
      modal: {
        objKey: '',
        label: '',
        data: null,
        parameters: {
          type: 'text',
          mode: 'input',
          required: false,
          options: [],
          listKey: '',
          number: {
            min: 0,
            max: 1,
            step: 1,
          },
          dropdown: {
            label: '',
            trackBy: '',
          },
        },
      },
      client: null,
      locationType: null,
      name: '',
      externalId: '',
      street1: '',
      street2: '',
      postalCode: '',
      city: '',
      countryCode: null,
      phone: '',
      isSaving: false,
      isLoading: false,
      location: null,
      wizardIndex: 0,
      isEditing: false,
      editingModal: '',
      internationalPhone: '',
      nationalPhone: '',
      currentCoordinates: {},
      currentLocation: {},
      isInternationalPhoneUpdate: false,
      isCoordinatesUpdate: true,
      isSubDivisionUpdate: true,
      featuresActiveList: [],
      featuresExceptionsList: {},
      featuresActiveListDetails: [],
      productLocatorStatus: false,
      warehouse: '',
      leadProductEmail: '',
      leadProductGenerationsEmails: [],
      leadProductAuthorizeFrom: '',
      clientFeaturesListWithChildFilled: [],
    }
  },
  async created() {
    this.isLoading = true
    this.locationType = this.currentClient.locationTypes[0]
    await this.getFrontOffices({})
    this.$store.commit('frontoffice/SET_CURRENT_FRONT_OFFICE', this.frontOffices[0])
    await Promise.all([
      this.getClientFeaturesList({ clientId: this.currentClient.id }),
      this.getFrontOfficeFeaturesList({ clientId: this.currentClient.id }),
    ])

    this.clientFeaturesListWithChildFilled = this.currentFrontOfficeFeatureList
      .map(item => {
        const matchingChild = this.currentClientFeatureList.find(child => child.featureKey === item.key)

        return matchingChild ? { ...item, ...matchingChild } : false
      })
      .filter(Boolean)

    const countriesClient = await locationCreateGeoip()
    this.countryCode = this.countries.find(countrie => countrie.countryCode === countriesClient.country) ?? null
    this.$v.$touch()
    this.isLoading = false
  },
  watch: {
    wizardIndex: {
      handler() {
        if (this.wizardIndex === 1) {
          this.initializeGeolocalisation()
        }
      },
    },
  },
  computed: {
    ...mapState({
      clients: state => state.client.clients,
      currentClient: state => state.client.currentClient,
      currLocale: state => state.backoffice.currentUser.locale,
      countries: state => state.backoffice.countries,
      frontOffices: state => state.frontoffice.frontOffices,
      subDivisions: state => state.tool.subDivisions,
      currentClientFeatureList: state => state.client.currentClientFeatureList,
      currentFrontOfficeFeatureList: state => state.frontoffice.currentFrontOfficeFeatureList,
    }),
    modalTitle() {
      return this.$t('common.modal.title.location', {
        location: this.name,
      })
    },
    breadcrumbs() {
      return [
        {
          label: this.$t('frontOffices.list.breadcrumb'),
          route: {
            name: 'Locations',
          },
        },
        {
          label: this.$t('locations.create.breadcrumb'),
          route: {
            name: 'LocationCreate',
          },
        },
      ]
    },
    activeClientLocator() {
      return this.currentClient?.productLocatorStatus === '1'
    },
    leadProductGenerationsEmailsString() {
      return this.leadProductGenerationsEmails.map(email => email.email).join(' - ')
    },
  },
  methods: {
    itemsExist() {
      let titlesList = []
      let itemsList = this.clientFeaturesListWithChildFilled
        .map(itemList => itemList.items?.length > 0 && itemList.items)
        .filter(item => item)
      itemsList = [].concat.apply([], itemsList)
      itemsList.forEach(item => titlesList.push(item.title))

      return false
    },
    ...mapActions({
      createLocation: 'location/createLocation',
      getClientFeaturesList: 'client/getClientFeaturesList',
      updateCurrentClient: 'client/updateCurrentClient',
      getSubDivisions: 'tool/getSubDivisions',
      getClient: 'client/getClient',
      getUniqueExternalId: 'location/getUniqueExternalId',
      getFrontOfficeFeaturesList: 'frontoffice/getFrontOfficeFeaturesList',
      getFrontOffices: 'frontoffice/getFrontOffices',
    }),
    editList(objKey, type, mode, listKey = null, label = '') {
      this.modal.objKey = objKey
      this.modal.label = {
        tag: this.$t(label),
      }
      this.modal.parameters = {
        type,
        mode,
        listKey,
      }
      this.editingModal = 'modal-simple-field'
      this.isEditing = true
      this.$nextTick(() => {
        this.$modal.show('modal-simple-field')
      })
    },
    editSimpleField(modalLabel, objKey = '', data = {}, label = '') {
      this.modal = {
        objKey,
        data,
        label,
      }
      this.editingModal = modalLabel
      this.isEditing = true
      this.$nextTick(() => {
        this.$modal.show(modalLabel)
      })
    },
    saveField(params, modalLabel) {
      this.$modal.hide(modalLabel)
      if (modalLabel === 'modal-sub-divisions') {
        this.currentLocation = params.objKey
      } else if (modalLabel === 'modal-coordinates') {
        this.currentCoordinates = params.objKey
      } else if (modalLabel === 'modal-simple-field') {
        this[Object.keys(params.objKey)[0]] = Object.values(params.objKey)[0]
      }
      this.closedModal()
    },
    closedModal() {
      this.isEditing = false
      this.editingModal = ''
    },
    async loadInternationalPhone() {
      if (this.phone) {
        const data = [
          {
            phone: this.phone,
            country: this.countryCode.countryCode,
          },
        ]
        try {
          const { data: internationalPhoneResult } = await getInternationalPhone(data)
          this.internationalPhone = internationalPhoneResult[0].e164
          this.nationalPhone = internationalPhoneResult[0].national
        } catch (err) {
          this.$log.error(err)
        }
      }
      this.isInternationalPhoneUpdate = false
    },
    async loadCoordinates() {
      const fullAddress = this.street1 + ',' + this.postalCode + ',' + this.city + ',' + this.countryCode.countryCode
      try {
        const { data: geoCodeResult } = await geocode(fullAddress)
        this.currentCoordinates = {
          city: this.city,
          countryCode: this.countryCode.countryCode,
          postalCode: this.postalCode,
          street1: this.street1,
          street2: this.street2,
          latitude: geoCodeResult.lat,
          longitude: geoCodeResult.lng,
        }
      } catch (err) {
        this.$log.error(err)
      }
      this.isCoordinatesUpdate = false
    },
    async loadSubdivisions() {
      const body = [
        {
          countryCode: this.countryCode.countryCode,
          latitude: this.currentCoordinates.latitude,
          longitude: this.currentCoordinates.longitude,
        },
      ]
      try {
        const coordsResults = await getGeonames(body)
        const { adminCode1, adminCode2, adminCode3 } = coordsResults[0]
        await this.getSubDivisions({
          locale: getShortLocal(this.currLocale),
          countryCode: this.countryCode.countryCode,
        })

        this.currentLocation = {
          ...this.currentLocation,
          subDivision1: adminCode1,
          subDivision2: adminCode2,
          subDivision3: adminCode3,
        }
      } catch (err) {
        this.$log.error(err)
      }
      this.isSubDivisionUpdate = false
    },
    async initializeGeolocalisation() {
      this.isInternationalPhoneUpdate = true
      this.isSubDivisionUpdate = true
      this.isCoordinatesUpdate = true
      await Promise.all([this.loadCoordinates(), this.loadInternationalPhone()])
      await this.loadSubdivisions()
    },
    onBack() {
      this.$router.push({ name: 'Locations' })
    },
    onComplete() {
      this.isSaving = true
      let locationData = {
        name: this.name,
        street1: this.street1,
        street2: this.street2,
        postalCode: this.postalCode,
        city: this.city,
        countryCode: this.countryCode.countryCode,
        phone: this.phone,
        clientId: this.currentClient.id,
        locationType: this.locationType,
        status: '1',
        latitude: this.currentCoordinates.latitude,
        longitude: this.currentCoordinates.longitude,
        externalId: this.externalId,
        subDivision1: this.currentLocation.subDivision1,
        subDivision2: this.currentLocation.subDivision2,
        subDivision3: this.currentLocation.subDivision3,
        internationalPhone: this.internationalPhone,
        nationalPhone: this.nationalPhone,
        featureList: this.featuresActiveList,
      }
      if (this.activeClientLocator) {
        locationData = {
          ...locationData,
          productLocatorStatus: this.productLocatorStatus === true ? '1' : '0',
          warehouse: this.warehouse,
          leadProductEmail: this.leadProductEmail,
          leadProductGenerationsEmails: this.leadProductGenerationsEmails,
          leadProductAuthorizeFrom: this.leadProductAuthorizeFrom,
        }
      }
      this.createLocation(locationData)
        .then(location => {
          this.location = location
          return this.updateCurrentClient(this.currentClient)
        })
        .then(() => {
          this.notificationSuccess('location', this.location.name, 'create')
          this.$router.push({ name: 'Location', params: { id: this.location.id } })
          this.isSaving = false
        })
        .catch(() => {
          this.isSaving = false
          this.notificationError()
        })
    },
    updateIndex(index) {
      this.wizardIndex = index
    },
    checkClient() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$v.$reset()
        return true
      }
      return false
    },
    checkLocator() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$v.$reset()
        return true
      }
      return false
    },
    onFeaturesInputDataChanged({ activeList, exceptions, activeListDetails }) {
      this.featuresActiveList = activeList
      this.featuresActiveListDetails = activeListDetails
      this.featuresExceptionsList = exceptions
    },
    mapSubDivision(division, level) {
      let subDivision = null
      if (level === 'level1') {
        subDivision = this.subDivisions[level].find(sub => sub.code === division)
      } else if (level === 'level2') {
        subDivision = this.subDivisions[level].find(
          sub => sub.code === division && sub.code_up === this.currentLocation.subDivision1
        )
      } else if (level === 'level3') {
        subDivision = this.subDivisions[level].find(
          sub => sub.code === division && sub.code_up === this.currentLocation.subDivision2
        )
      }
      return subDivision ? subDivision.name : this.$t('errors.subDivisions')
    },
    delayTouch($v) {
      $v.$reset()
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v))
      }
      touchMap.set($v, setTimeout($v.$touch, 300))
    },
  },
  validations() {
    return {
      name: {
        required: requiredIf(() => {
          return this.wizardIndex === 0
        }),
      },
      externalId: {
        async isUnique(value) {
          // standalone validator ideally should not assume a field is required
          if (value === '') {
            return true
          }

          // simulate async call, fail for all logins with even length
          const isUnique = await this.getUniqueExternalId(value)
          return !isUnique
        },
      },
      street1: {
        required: requiredIf(() => {
          return this.wizardIndex === 0
        }),
      },
      city: {
        required: requiredIf(() => {
          return this.wizardIndex === 0
        }),
      },
      countryCode: {
        required: requiredIf(() => {
          return this.wizardIndex === 0
        }),
      },
      locationType: {
        required: requiredIf(() => {
          return this.wizardIndex === 0
        }),
      },
      currentCoordinates: {
        required: requiredIf(() => {
          return this.wizardIndex === 1
        }),
      },
      currentLocation: {
        subDivision1: {
          required: requiredIf(() => {
            return this.wizardIndex === 1 && this.subDivisions?.level1.length > 0
          }),
        },
        subDivision2: {
          required: requiredIf(() => {
            return this.wizardIndex === 1 && this.subDivisions?.level2.length > 0
          }),
        },
      },
      leadProductEmail: {
        email,
        required: requiredIf(() => {
          return this.wizardIndex === 3 && this.productLocatorStatus === true
        }),
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.locations-create {
  &__lms {
    &__status {
      &__icon {
        margin-right: $gutter-mobile;
        border: 1px solid;
        border-radius: 50%;
        padding: 5px;
        width: 36px;
        height: 36px;

        .locations-create__lms__status--active & {
          color: map-get($generic-color-variants, 'success');
        }

        .locations-create__lms__status--inactive & {
          color: map-get($generic-color-variants, 'error');
        }
      }

      &__state {
        margin-top: 2px;

        .locations-create__lms__status--active & {
          color: map-get($generic-color-variants, 'success');
        }

        .locations-create__lms__status--inactive & {
          color: map-get($generic-color-variants, 'error');
        }
      }

      &__toggle {
        margin-left: $gutter-mobile;
      }
    }
  }

  &__wrapper {
    @media (min-width: $screen-sm) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &__input,
    &__address {
      margin-bottom: $gutter-mobile;

      @media (min-width: $screen-sm) {
        width: calc(100% / 2 - #{$gutter-tablet});
      }

      @media (min-width: $screen-sm) {
        margin-bottom: $gutter-tablet;
      }

      &__input {
        &--successive {
          margin-bottom: 8px;
        }
      }
    }
  }

  &__input {
    margin-bottom: $gutter-mobile;

    @media (min-width: $screen-sm) {
      margin-bottom: $gutter-tablet;
    }

    &--successive {
      margin-bottom: 8px;
    }
  }

  &__featuresSelection {
    /* stylelint-disable selector-pseudo-element-no-unknown */
    ::v-deep .feature-list-selection {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &__section {
        min-width: 40%;
      }
    }
    /* stylelint-enable selector-pseudo-element-no-unknown */
  }

  &__grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: (-$gutter-mobile / 2);

    @media (min-width: $screen-sm) {
      margin: (-$gutter-tablet / 2);
    }

    @media (min-width: $screen-xl) {
      margin: (-$gutter-desktop / 2);
    }

    &__coordinates {
      &__section {
        display: flex;
        justify-content: space-between;

        &__label {
          font-weight: 500;
        }
      }
    }

    &__title {
      margin: calc(#{$gutter-mobile} * 2) 0 $gutter-mobile 0;
    }
  }

  &__gps {
    &__coordinates {
      &__section {
        display: flex;
        justify-content: space-between;

        &__label {
          font-weight: 500;
        }
      }
    }
  }

  &__features {
    margin: 0 auto;
  }
}
</style>
