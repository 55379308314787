<template>
  <div class="ui-wizard" :style="style">
    <div class="ui-wizard__header">
      <ui-button
        button="secondary"
        icon="previous"
        :icon-only="true"
        :label="$t('common.button.back')"
        class="ui-wizard__header__back"
        @click="emitBack"
      />
      <div class="ui-wizard__header__title">
        {{ title }}
      </div>
      <screen-breadcrumb :breadcrumbs="breadcrumbs" class="ui-wizard__header__breadcrumb" />
    </div>
    <div class="ui-wizard__container">
      <div class="ui-wizard__container__wrapper">
        <ul class="ui-wizard__container__wrapper__tabs">
          <li
            class="ui-wizard__container__wrapper__tabs__item"
            :class="{ 'ui-wizard__container__wrapper__tabs__item--active': tab.active }"
            v-for="(tab, idx) in tabs"
            :key="`tab-${idx}`"
            :style="{ width: `calc(100% / ${tabs.length})` }"
          >
            <div class="ui-wizard__container__wrapper__tabs__item__number">
              {{ tab.tabNumber }}
            </div>
            <div class="ui-wizard__container__wrapper__tabs__item__title">
              {{ tab.title }}
            </div>
          </li>
        </ul>
        <div class="ui-wizard__container__wrapper__content">
          <slot></slot>
        </div>
        <div class="ui-wizard__container__wrapper__footer">
          <div class="ui-wizard__container__wrapper__footer__left">
            <ui-button
              class="ui-wizard__container__wrapper__footer__left__prev"
              button="cta"
              :variant="productLine"
              :label="$t('common.button.prev')"
              :disabled="isSaving"
              v-if="displayPrevButton"
              @click="prevTab"
            />
          </div>
          <div class="ui-wizard__container__wrapper__footer__right">
            <ui-button
              class="ui-wizard__container__wrapper__footer__left__finish"
              button="cta"
              variant="success"
              :label="$t('common.button.finish')"
              :disabled="isSaving || isLoading"
              v-if="isLastStep"
              @click="nextTab"
            />
            <ui-button
              class="ui-wizard__container__wrapper__footer__left__next"
              button="cta"
              :variant="productLine"
              :label="$t('common.button.next')"
              :disabled="isSaving || !isValid || isLoading"
              v-else
              @click="nextTab"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UiButton from '@/components/UI/Button.vue'
import ScreenBreadcrumb from '@/components/Screen/Breadcrumb.vue'
import { productColor } from '@/config/productColor.config'

export default {
  name: 'UiWizard',
  components: {
    UiButton,
    ScreenBreadcrumb,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    breadcrumbs: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isSaving: {
      type: Boolean,
      required: false,
      default: false,
    },
    isValid: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  provide() {
    return {
      addTab: this.addTab,
      deleteTab: this.deleteTab,
    }
  },
  mounted() {
    this.initializeTabs()
  },
  data() {
    return {
      tabs: [],
      activeTabIndex: 0,
    }
  },
  computed: {
    style() {
      return {
        '--product-color': productColor[this.productLine],
      }
    },
    productLine() {
      return this.$route.meta.productLine || 'none'
    },
    tabCount() {
      return this.tabs.length
    },
    isLastStep() {
      return this.activeTabIndex === this.tabCount - 1
    },
    displayPrevButton() {
      return this.activeTabIndex !== 0
    },
  },
  methods: {
    emitBack() {
      this.$emit('back')
    },
    emitTabChange(prevIndex, nextIndex) {
      this.$emit('on-change', prevIndex, nextIndex)
      this.$emit('update:startIndex', nextIndex)
    },
    addTab(item) {
      const index = this.$slots.default.indexOf(item.$vnode)
      this.tabs.splice(index, 0, item)
      this.tabs.forEach((tab, index) => {
        tab.tabNumber = index + 1
      })
    },
    deleteTab(item) {
      const index = this.tabs.indexOf(item)
      this.tabs.splice(index, 1)
      this.tabs.forEach((tab, index) => {
        tab.tabNumber = index + 1
      })
    },
    nextTab() {
      let cb = () => {
        if (this.activeTabIndex < this.tabCount - 1) {
          this.changeTab(this.activeTabIndex, this.activeTabIndex + 1)
        } else {
          this.$emit('on-complete')
        }
      }
      this.beforeTabChange(this.activeTabIndex, cb)
    },
    prevTab() {
      if (this.activeTabIndex > 0) {
        this.changeTab(this.activeTabIndex, this.activeTabIndex - 1)
      }
    },
    beforeTabChange(index, callback) {
      let oldTab = this.tabs[index]
      if (oldTab && oldTab.beforeChange !== undefined) {
        let tabChangeRes = oldTab.beforeChange()
        this.validateBeforeChange(tabChangeRes, callback)
      } else {
        callback()
      }
    },
    validateBeforeChange(promiseFn, callback) {
      let validationResult = promiseFn === true
      this.executeBeforeChange(validationResult, callback)
    },
    executeBeforeChange(validationResult, callback) {
      this.$emit('on-validate', validationResult, this.activeTabIndex)
      if (validationResult) {
        callback()
      }
    },
    changeTab(oldIndex, newIndex, emitChangeEvent = true) {
      let oldTab = this.tabs[oldIndex]
      let newTab = this.tabs[newIndex]
      if (oldTab) {
        oldTab.active = false
      }
      if (newTab) {
        newTab.active = true
      }
      if (emitChangeEvent && this.activeTabIndex !== newIndex) {
        this.emitTabChange(oldIndex, newIndex)
      }
      this.activeTabIndex = newIndex
      // this.activateTabAndCheckStep(this.activeTabIndex)
      return true
    },
    deactivateTabs() {
      this.tabs.forEach(tab => {
        tab.active = false
      })
    },
    activateTab(index) {
      this.deactivateTabs()
      let tab = this.tabs[index]
      if (tab) {
        tab.active = true
      }
    },
    initializeTabs() {
      if (this.tabs.length > 0) {
        this.activateTab(this.activeTabIndex)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-wizard {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  z-index: 6;
  background-color: var(--bg-color);

  @media (min-width: $screen-sm) {
    position: initial;
    z-index: 1;
    background-color: initial;
    height: 100%;
  }

  &__header {
    @include container;

    align-items: center;
    border-bottom: 1px solid $generic-color-text-alt;
    background-color: var(--product-color);
    height: 56px;

    @media (min-width: $screen-sm) {
      flex-direction: column;
      align-items: flex-start;
      border-bottom: 0;
      background: initial;
      padding-top: $gutter-tablet;
      padding-bottom: $gutter-tablet;
      height: initial;
    }

    @media (min-width: $screen-xl) {
      padding-top: $gutter-desktop;
      padding-bottom: $gutter-desktop;
    }

    &__breadcrumb {
      display: none;

      @media (min-width: $screen-sm) {
        display: flex;
        margin-top: 3px;
      }
    }

    &__back {
      margin-right: 16px;
      margin-left: $generic-button-offset;
      color: $generic-color-text-alt;

      @media (min-width: $screen-sm) {
        display: none;
      }
    }

    &__title {
      color: $generic-color-text-alt;
      font-size: 1rem;
      font-weight: bold;

      @media (min-width: $screen-sm) {
        color: var(--text-color);
        font-size: $font-size-lg;
        font-weight: 600;
      }
    }
  }

  &__container {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    @media (min-width: $screen-sm) {
      @include container;

      margin-top: -24px;
      padding-top: 24px;
      padding-bottom: 24px;
      overflow-y: hidden;
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;

      @media (min-width: $screen-sm) {
        border-radius: $radius-default;
        box-shadow: 0 0 15px var(--box-shadow-color);
        background: var(--bg-color);
        width: 100%;
        overflow: hidden;
      }

      &__tabs {
        display: flex;
        margin: 0;
        background: var(--product-color);
        padding: 0;
        padding: $gutter-mobile / 2 0;
        list-style: none;

        &__item {
          display: flex;
          flex-direction: column;
          align-items: center;
          opacity: 0.5;
          padding: 0 $gutter-mobile;
          color: $generic-color-text-alt;

          &--active {
            opacity: 1;
          }

          &__title {
            margin-top: 4px;
            text-align: center;
            font-size: 0.75rem;
          }

          &__number {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 2px solid;
            border-radius: 50%;
            width: 32px;
            height: 32px;
          }
        }
      }

      &__content {
        padding: $gutter-mobile;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;

        @media (min-width: $screen-sm) {
          display: flex;
          justify-content: center;
          padding: $gutter-tablet;
        }

        @media (min-width: $screen-xl) {
          padding: $gutter-desktop;
        }
      }

      &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid var(--border-color);
        padding: $gutter-mobile / 2 $gutter-mobile;

        @media (min-width: $screen-sm) {
          padding: $gutter-tablet / 2 $gutter-tablet;
        }

        @media (min-width: $screen-xl) {
          padding: $gutter-tablet / 2 $gutter-desktop;
        }
      }
    }
  }
}
</style>
